<template>
  <div>
    <div class="vs-row hero">
      <div class="hero-text self-center mx-20">
        <p class="text-5xl font-bold pl-4">{{$t('My Cart')}}</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>
    <div class="mx-10 divide-y divide-gray-300">
      <div class="mb-5">
        <div v-if="$route.params.lang === 'en'" class="vs-row mt-10 pb-20">
          <div class="vs-col lg:w-1/2 md:w-1/3 w-full">
            <p class="text-2xl font-bold">{{$t('My Cart')}}</p>
          </div>
          <div class="vs-col lg:w-1/2 md:w-2/3 w-full flex justify-end">
            <vs-button class="btn" color="#00DCDC" text-color="#fff" icon="favorite">{{$t('Add All To Wish List')}}</vs-button>
            <vs-button class="btn ml-3" color="danger" text-color="#fff" icon="delete">{{$t('Remove All')}}</vs-button>
          </div>
        </div>
        <div v-else class="vs-row mt-10 pb-20">
          <div class="vs-col lg:w-1/2 md:w-2/3 w-full flex justify-end">
            <vs-button class="btn" color="#00DCDC" text-color="#fff" icon="favorite">{{$t('Add All To Wish List')}}</vs-button>
            <vs-button class="btn mr-3" color="danger" text-color="#fff" icon="delete">{{$t('Remove All')}}</vs-button>
          </div>
          <div class="vs-col lg:w-1/2 md:w-1/3 w-full">
            <p class="text-2xl font-bold">{{$t('My Cart')}}</p>
          </div>
        </div>
        <template v-if="cartIrems && cartIrems.length > 0">
          <div class="vs-row flex mb-3" v-for="(item, index) in cartIrems" :key="index">
            <div class="vs-col w-1/3 card-body">
              <img class="h-36 w-full pr-3" :src="item.banner ? item.banner : item.image" alt="">   
            </div>
            <div class="vs-col w-2/3 border pl-5 pr-2 pb-2 pt-3">
              <p class="text-lg">{{item.title? item.title : $route.params.lang === 'en' ? item.name_en : item.name_ar}}</p>
              <vs-input-number v-if="!item.location" color="#00DCDC" v-model="item.book.quantity"/>
              <div v-if="item.location" class="mt-1">
                <span class="text-blue">{{$t('Price: ')}}</span><span class="text-orange">{{ item.location[0].price * item.book.child.length}} {{$t('KWD')}}</span>
              </div>
              <div v-else>
                <span class="text-blue">{{$t('Price: ')}}</span><span class="text-orange">{{ item.book.quantity * item.price}} {{$t('KWD')}}</span>
              </div>
              <div v-if="item.location" class="my-2">
                <span class="text-blue">{{$t('1 Activity For')}} {{item.book.child ? item.book.child.length : 0}} {{$t('Children')}}</span>
              </div>
              <div class="vs-row self-center text-base flex">
                <div v-if="item.location" class="vs-col w-1/2 justify-start flex">
                  <p class="w-1/2">{{item.book.date.split('T')[0]}}</p>
                  <p class="w-1/2">{{item.book.time}}</p>
                </div>
                <div :class="{'w-full':flag, 'w-1/2':!flag }" class="vs-col flex justify-end divide-x divide-gray-300 text-gray-500">
                  <!-- <span class="pr-2 text-blue flex">
                    <vs-icon size="20px" class="self-center" color="#00DCDC" icon="edit"></vs-icon>
                    <span class="self-center">Edit</span>
                  </span> -->
                  <span @click="removeItem(item, index)" class="cursor-pointer px-2 text-red-600 flex">
                    <vs-icon size="20px" class="self-center" icon="delete"></vs-icon>
                    <span class="self-center">{{$t('Remove')}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template>
          <p class="text-lg flex justify-center text-gray-400">{{$t('No Data Available')}}</p>
        </template>
      </div>
      <div class="vs-row pt-5 m-auto mb-40">
        <div class="vs-col xl:w-1/2 lg:w-1/2 md:w-full sm:w-full w-full">
          <div class="border mx-1 px-2 py-3">
            <p>{{$t('Promocode')}}</p>
            <p class="text-gray-500 text-sm mt-1">{{$t('Enter your promocode if you have it.')}}</p>
            <div class="flex my-2">
              <div class="self-center">
                <vs-input class="input w-full" color="pr/imary" placeholder="Enter Code" v-model="promoCode"/>
              </div>
              <div class="w-full self-center">
                <vs-button @click="applyCode()" color="warning" class="float-right pl-5">{{$t('Apply')}}</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="vs-col xl:w-1/2 lg:w-1/2 lg:mt-0 mt-3 w-full">
          <div class="border mx-1 px-2 py-3">
            <p>{{$t('Shipping Address')}}</p>
            <div class="text-sm mt-1">
              <p v-if="selectedAddress">{{selectedAddress.title}}</p>
              <div class="flex vs-row">
                <div class="text-gray-400 vs-col w-3/4">
                  <p v-if="selectedAddress">{{selectedAddress.area_name}}, {{selectedAddress.block}},</p>
                  <p v-if="selectedAddress">{{selectedAddress.street}}, {{selectedAddress.building}}</p>
                </div>
                <span class="vs-col w-1/4 flex justify-end">
                  <vs-icon @click="openAddressModal()" class="cursor-pointer" icon="edit" color="primary"></vs-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="vs-col xl:w-1/3 lg:w-1/3 lg:my-0 mt-3 mb-8 w-full">
          <div class="border mx-1 px-2 py-3">
            <p>Payment Method</p>
            <div class="text-sm mt-1 mb-8">
              <div class="flex">
                <vs-icon class="self-center mr-1" icon="credit_card" color="warning"></vs-icon>
                <p class="self-center">Visa **** 0959</p>
              </div>
              <div class="flex vs-row justify-end">
                <vs-icon icon="edit" color="primary"></vs-icon>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="vs-row pt-5 m-auto">
        <div class="vs-row flex px-3">
          <p class="vs-col w-1/2">{{$t('Price: ')}}</p>
          <p class="vs-col w-1/2 text-blue flex justify-end">{{price}}</p>
        </div>
        <div class="vs-row flex mt-3 px-3">
          <p class="vs-col w-1/2">{{$t('Shipping: ')}}</p>
          <p class="vs-col w-1/2 text-blue flex justify-end">{{sum}}</p>
        </div>
        <div class="vs-row flex mt-3 bg-gray-200 px-3 py-2">
          <p class="vs-col w-1/2">{{$t('Total Price: ')}}</p>
          <p class="vs-col w-1/2 flex justify-end">{{sum + price}} {{$t('KWD')}}</p>
        </div>
      </div>
    </div>
    <!-- <div class="mt-12 mx-10">
      <div class="vs-row flex">
        <p class="vs-col w-1/2">You Might Also Like To Add</p>
        <p class="vs-col w-1/2 text-blue flex justify-end">See All</p>
      </div>
      <div>
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="(o, index) in 9" :key="o" :offset="index > 0 ? 2 : 0">
            <el-card class="text-left w-96" :body-style="{ padding: '0px' }">
              <div class="cardBody">
                <span class="material-icons btn">favorite</span>
                <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image">
              </div>
              <div class="footer" style="padding: 14px;">
                <span>The Hill-top Party in English</span>
                <div class="mt-1">
                  <i class="el-icon-location text-blue"></i>
                  <span class="time mx-1 text-sm text-gray-400">At Home | 2-12 Years | Books</span>
                  <div class="vs-row mx-1 bottom flex">
                    <div class="vs-col w-3/4 self-center">
                      <span class="text-blue">Price: </span><span class="text-orange">2.500 KWD</span>
                    </div>
                    <div class="vs-col w-1/4 flex justify-end">
                      <vs-icon icon="add_circle" color="primary" size="30px"></vs-icon>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div> -->
    <vs-button :disabled="!selectedAddress.id" @click="payFatoorah()" class="w-full mt-10" color="warning" text-color="#fff">{{$t('Confirm & pay')}}</vs-button>
    <vs-prompt
      @close="closeModal"
      :active.sync="addressModal"
      title="Edit Address"
      :buttons-hidden="true">
      <VuePerfectScrollbar :is="'div'" class="scroll-area" :settings="settings">
        <form>
          <div class="vs-row">
            <div v-for="address in addressess" @click="changeAddress(address)" :key="address.index" class="vs-col w-full">
              <div :class="address.id === selectedAddress.id ? 'selected' : ''" class="border-2 border-slate-300	rounded-md p-6 mr-3 mb-4">
                <h2>{{address.title}}</h2>
                <h3>{{address.area_name}}, {{address.block}}, {{address.street}}, {{address.building}}</h3>
              </div>
            </div>
          </div>
        </form>
      </VuePerfectScrollbar>
      <div class="p-4">
        <vs-button type="filled" color="primary" @click="saveAddress()" class="w-full  float-right m-5 block">{{$t('Edit')}}</vs-button>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from '../axios.js'

export default {
  // components: { Swiper, SwiperSlide },
  data () {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'My Cart',
          active: true
        }
      ],
      price: 0,
      codes: [],
      selectedAddress: {},
      number: 7,
      flag: true,
      promoCode: '',
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 10,
        slidesPerGroup: 3,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      addressess: [],
      cartIrems: [],
      codesDic: {},
      addressModal: false,
      sum: 0
    }
  },
  watch: {
    cartIrems: {
      handler () {
        this.sum = 0
        this.price = 0
        this.cartIrems.forEach(item => {
          if (item.location) {
            this.price = this.price + (item.location[0].price * item.book.child.length)
            this.sum = this.sum + Number(item.club_details.shipping_charge)
          } else {
            this.price = this.price + (item.book.quantity * item.price)
          }
        })
      },
      deep: true
    }
  },
  methods: {
    payFatoorah () {
      axios.post('v1/user/fatura_token', {pay_amount: this.price + this.sum}, {headers: this.headers})
      .then((response) => {
        // window.location = response.data.payload.RedirectUrl
        console.log(response)
      })
    },
    applyCode() {
      console.log(this.cartIrems)
      const code = this.codesDic[this.promoCode]
      this.promoCode = ''
      console.log(code);
      this.cartIrems.forEach(item => {
        if (item.location) {
          switch (code.applied_on) {
            case 'all':
            break;
            case 'club':
              if (Number(item.club_details.id) === Number(code.club_activity_id)) {
                item.total = code.type === 'Fixed' ? item.location[0].price - Number(code.value) : (item.location[0].price * Number(code.value)) / 100
              }
            break;
            case 'activity':
              if (Number(item.id) === Number(code.club_activity_id)) {
                item.total = code.type === 'Fixed' ? item.location[0].price - Number(code.value) : (item.location[0].price * Number(code.value)) / 100
              }
            break;
          }

        }
      })
      console.log(this.cartIrems)
    },
    changeAddress (address) {
      this.selectedAddress = address
    },
    loadPromoCode () {
      this.$vs.loading()
      axios.get('v1/promoCode')
      .then((response) => {
        this.$vs.loading.close()
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.codes = response.data.payload
          response.data.payload.forEach(element => {
            this.codesDic[String(element.code)] = element
          })
          this.$vs.loading.close()
        }
      })
    },
    saveAddress () {
      this.addressModal = false
    },
    loadAddresses () {
      this.$vs.loading()
			const user = JSON.parse(localStorage.getItem('user_data'))
      axios.post('v1/user/list_address', {user_id: user.user_id}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.addressess = response.data.payload
          this.$vs.loading.close()
        }
      })
    },
    openAddressModal () {
      this.addressModal = true
    },
    removeItem (item, index) {
      
      this.cartIrems = JSON.parse(localStorage.getItem('cart'))
      this.cartIrems.splice(index, 1)
      if (item.location) {
        this.price = this.price - (item.location[0].price * item.book.child.length)
        this.sum = this.sum - Number(item.club_details.shipping_charge)
      } else {
        this.price = this.price - (item.book.quantity * item.price)
      }
      localStorage.setItem('cart', JSON.stringify(this.cartIrems))

    },
    closeModal () {},
    editAddress () {
      this.$validator.validateAll().then(result => {
        if (result) {
          console.log(result)
        }
      })
    }
  },
  comments: {
    VuePerfectScrollbar
  },
  created () {
    this.loadPromoCode()
    this.loadAddresses()
    this.cartIrems = JSON.parse(localStorage.getItem('cart'))
  }
}
</script>


<style lang="scss" scoped>
.hero {
  background-image: url('../assets/images/footer.jpg');
}
.selected {
  background: aliceblue;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: 75vh;

  &:not(.ps) {
    overflow-y: auto;
  }
}

.swiper {
  height: 400px;
  width: 100%;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .swiper-button-prev, .swiper-button-next {
    background-color: #F89416;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    color: #fff !important;
    font-size: small;
  }
}
</style>

<style>
.input .vs-con-input  {
  margin-left: 0px !important;
}
</style>

<style scoped>
.blue {
  color: #00DCDC;
}
.image {
  width: 100%;
  height: 200px;
  display: block;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color: gray;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.btn {
  padding: 7px 20px;
  border-radius: 5px;
}
.vs-input-number {
  justify-content: left;
  background: none;
}
.card-body {
  position: relative;
}
.card-body .fav-icon {
  color:gray;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  top: 2%;
  right: 4%;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
</style>